"use client";

import { Children, cloneElement, isValidElement, useState } from "react";

import { useLongPress } from "@uidotdev/usehooks";
import classNames from "classnames";

import {
  Tooltip as TooltipOriginal,
  TooltipTrigger,
  TooltipProps as TooltipPropsOriginal,
} from "@components/common/tooltip";

export type TooltipProps = TooltipPropsOriginal & {
  isMobile: boolean;
};

const Tooltip = ({ isMobile, ...tooltipProps }: TooltipProps): JSX.Element =>
  isMobile ? (
    <TooltipMobile {...tooltipProps} />
  ) : (
    <TooltipOriginal {...tooltipProps} />
  );

const TooltipMobile = (props: TooltipPropsOriginal): JSX.Element => {
  const { children, open, ...tooltipProps } = props;
  const [showTooltip, setShowTooltip] = useState(false);

  const tooltipEvents = useLongPress(() => setShowTooltip(true), {
    onFinish: () => setShowTooltip(false),
    onCancel: () => setShowTooltip(false),
    threshold: 200,
  });

  const updatedChildren = Children.map(children, (child) => {
    if (
      isValidElement(child) &&
      child.type === TooltipTrigger &&
      open !== false
    ) {
      const props = {
        ...child.props,
        className: classNames(child.props.className, "not-selectable"),
      };
      return cloneElement(child, {
        ...tooltipEvents,
        ...props,
      });
    }

    return child;
  });

  return (
    <TooltipOriginal
      {...tooltipProps}
      open={open === false ? open : showTooltip}
    >
      {updatedChildren}
    </TooltipOriginal>
  );
};

export default Tooltip;

export {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  type TooltipProps as TooltipPropsOriginal,
} from "@components/common/tooltip";
